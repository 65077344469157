import { isEmpty } from 'lodash';
import moment from 'moment';

import {
  Vessel as VesselType,
  VesselTypeDescription,
  CompanyType,
  ManoeuvreType,
  Stopover as StopoverType,
  Vessel,
  CompanyTypeType,
  FinancialTableType,
  Docking as DockingType,
  Product as ProductType,
  WorkPeriodType,
  OperationalOperationToSave,
  OperationalOperationType,
  StoppageToSave,
  OperationProductType,
  OperationProductCeMercanteType,
  OperationalOperationProductToSave,
  OperationalMerchantCeToSave,
  OperationProductStoragesMercanteType,
  OperationalStorageMerchantToSave,
  OperationalCargoStorageMerchantToSave,
  CargoStorageMerchantType,
  CeMerchantGroupbyNcmItemType,
  OperationManifestType,
  OperationManifestToSave,
  StopoverTypeDescription,
  PilotType,
  ProvisionedCargoType,
  ProvisionedCargoToSave,
  SystemParamItemType,
  NcmItemFilters,
} from '../types';
import {
  booleanList,
  companyTypes,
  dockLineTypes,
  shipTypesList,
  unitsList,
} from './lists';
import { translateDockLineType } from './translateDockLineType';
import {
  addSuffix,
  booleanToString,
  createDate,
  createDateString,
  createDateStringPtBr,
  formatNumberToLocale,
  isNullOrUndefined,
  maskPhoneNumber,
  maskString,
  removeSpecialCharacters,
} from './utils';

export function formatStopoverToSave(updates: any) {
  const updatesObj: any = { ...handleDateAndTimeStopover(updates) };
  updatesObj.charterer = updates.charterer?.id;
  updatesObj.shipping_agency = updates.shipping_agency?.id;
  updatesObj.protective_agency = updates.protective_agency?.id || null;
  updatesObj.navigation_type = updates.navigation_type?.id || null;
  updatesObj.vessel = updates.vessel.id;
  updatesObj.last_port = updates.last_port?.bi_trigram || null;
  updatesObj.next_port = updates.next_port?.bi_trigram || null;
  updatesObj.duv = removeSpecialCharacters(updates.duv);
  updatesObj.dockings = formatDockingsToSave(updates.dockings || []);
  updatesObj.documents = updates.documents?.map((doc: any) => {
    return {
      ...doc,
      consent_type: doc.consent_type.id,
    };
  });

  return updatesObj;
}

export function formatStopoverToForm(stopover: StopoverType) {
  return {
    ...stopover,
    expected_arrival: stopover.expected_arrival
      ? moment(stopover.expected_arrival)
      : null,
    expected_departure: stopover.expected_departure
      ? moment(stopover.expected_departure)
      : null,
    expected_arrival_by_agent: stopover.expected_arrival_by_agent
      ? moment(stopover.expected_arrival_by_agent)
      : null,
    actual_time_of_arrival: stopover.actual_time_of_arrival
      ? moment(stopover.actual_time_of_arrival)
      : null,
    actual_anchorage_arrival_time: stopover.actual_anchorage_arrival_time
      ? moment(stopover.actual_anchorage_arrival_time)
      : null,
    expected_arrival_ais: stopover.expected_arrival_ais
      ? moment(stopover.expected_arrival_ais)
      : null,
    port_stay_finished: stopover.port_stay_finished
      ? moment(stopover.port_stay_finished)
      : null,
    dockings: formatDockingsDates(stopover.dockings || []),
    // para campos de select que não aceitam boolean:
    ...booleanToString(stopover),
    vessel: {
      ...stopover.vessel,
      dock_line_type: translateDockLineType(stopover.vessel?.dock_line_type),
      // name: `${stopover.vessel.imo} - ${stopover.vessel.name}`,
    },
  };
}

export function formatDockingToForm(element: any) {
  if (element && Object.keys(element).length > 0) {
    return {
      ...element,
      berthing: [
        createDate(element.expected_berthing),
        createDate(element.expected_unberthing),
      ],
      pilot: [
        createDate(element.pilot_expected_on_board),
        createDate(element.pilot_expected_leaving_on_board),
      ],
      real_time_of_docking: createDate(element.real_time_of_docking),
      real_time_of_undocking: createDate(element.real_time_of_undocking),
      first_cable_tied_in: createDate(element.first_cable_tied_in),
      last_cable_tied_in: createDate(element.last_cable_tied_in),
      first_cable_untied_in: createDate(element.first_cable_untied_in),
      last_cable_untied_in: createDate(element.last_cable_untied_in),
      operation_summary: {
        ...element.operation_summary,
        expected_start: createDate(element.operation_summary?.expected_start),
        expected_finish: createDate(element.operation_summary?.expected_finish),
        real_start: createDate(element.operation_summary?.real_start),
        real_finish: createDate(element.operation_summary?.real_finish),
      },
      real_time_of_anchoring: createDate(element.real_time_of_anchoring),
      real_time_of_unanchoring: createDate(element.real_time_of_unanchoring),
      expected_anchoring: createDate(element.expected_anchoring),
      expected_unanchoring: createDate(element.expected_unanchoring),
      start_of_internal_navigation: createDate(
        element.start_of_internal_navigation
      ),
      end_of_internal_navigation: createDate(
        element.end_of_internal_navigation
      ),
      departure_time_at_port_entrance: createDate(
        element.departure_time_at_port_entrance
      ),
      is_support_vessel: element.is_support_vessel?.toString(),
      docking_manoeuvre: {
        ...element.docking_manoeuvre,
        pilot_board: [
          createDate(element.docking_manoeuvre?.pilot_on_board),
          createDate(element.docking_manoeuvre?.pilot_leave_on_board),
        ],
        pilot_on_board: createDate(element.docking_manoeuvre?.pilot_on_board),
        pilot_leave_on_board: createDate(
          element.docking_manoeuvre?.pilot_leave_on_board
        ),
        supervised: element.docking_manoeuvre?.supervised?.toString(),
        confirmed: element.docking_manoeuvre?.confirmed?.toString(),
        did_internal_navigation:
          element.docking_manoeuvre?.did_internal_navigation?.toString(),
      },
      undocking_manoeuvre: {
        ...element.undocking_manoeuvre,
        pilot_board: [
          createDate(element.undocking_manoeuvre?.pilot_on_board),
          createDate(element.undocking_manoeuvre?.pilot_leave_on_board),
        ],
        pilot_on_board: createDate(element.undocking_manoeuvre?.pilot_on_board),
        pilot_leave_on_board: createDate(
          element.undocking_manoeuvre?.pilot_leave_on_board
        ),
        supervised: element.undocking_manoeuvre?.supervised?.toString(),
        confirmed: element.undocking_manoeuvre?.confirmed?.toString(),
        did_internal_navigation:
          element.undocking_manoeuvre?.did_internal_navigation?.toString(),
        did_departure_at_port_entrance:
          element.undocking_manoeuvre?.did_departure_at_port_entrance?.toString(),
      },
      expected_berthing: createDate(element.expected_berthing),
      expected_unberthing: createDate(element.expected_unberthing),
      pilot_expected_on_board: createDate(element.pilot_expected_on_board),
      pilot_expected_on_board_undocking: createDate(
        element.pilot_expected_on_board_undocking
      ),
      pilot_expected_leaving_on_board: createDate(
        element.pilot_expected_leaving_on_board
      ),
      pilot_expected_leaving_on_board_undocking: createDate(
        element.pilot_expected_leaving_on_board_undocking
      ),
      real_time_of_port_arrival: createDate(element.real_time_of_port_arrival),
      products: element.products ? formatProductsToForm(element.products) : [],
      wait_at_anchoring: element.wait_at_anchoring?.toString(),
      confirmed_manoeuvre: element.confirmed_manoeuvre?.toString(),
    };
  }
  return null;
}

export function formatDockingsToSave(values: any) {
  return values.map((docking: any, index: number) => {
    return {
      ...docking,
      ...formatDockingToSave(docking, index),
    };
  });
}

export function formatDockingToSave(docking: DockingType, index?: number) {
  return {
    ...docking,
    index: index || docking.index,
    expected_berthing: createDateString(docking.expected_berthing),
    pilot_expected_on_board: createDateString(docking.pilot_expected_on_board),
    pilot_expected_on_board_undocking: createDateString(
      docking.pilot_expected_on_board_undocking
    ),
    pilot_expected_leaving_on_board: createDateString(
      docking.pilot_expected_leaving_on_board
    ),
    pilot_expected_leaving_on_board_undocking: createDateString(
      docking.pilot_expected_leaving_on_board_undocking
    ),
    actual_time_of_arrival: createDateString(docking.actual_time_of_arrival),
    expected_unberthing: createDateString(docking.expected_unberthing),
    start_of_internal_navigation: createDateString(
      docking.start_of_internal_navigation
    ),
    end_of_internal_navigation: createDateString(
      docking.end_of_internal_navigation
    ),
    departure_time_at_port_entrance: createDateString(
      docking.departure_time_at_port_entrance
    ),
    real_time_of_anchoring: createDateString(docking.real_time_of_anchoring),
    real_time_of_unanchoring: createDateString(
      docking.real_time_of_unanchoring
    ),
    expected_anchoring: createDateString(docking.expected_anchoring),
    expected_unanchoring: createDateString(docking.expected_unanchoring),
    docking_manoeuvre: formatManoeuvreToSave(
      docking.docking_manoeuvre || ({} as ManoeuvreType)
    ),
    undocking_manoeuvre: formatManoeuvreToSave(
      docking.undocking_manoeuvre || ({} as ManoeuvreType)
    ),
    docking_place: docking.docking_place?.tag || null,
    bollard_bow_launcher: docking.bollard_bow_launcher?.tag || null,
    bollard_bow_spring: docking.bollard_bow_spring?.tag || null,
    bollard_bow_across: docking.bollard_bow_across?.tag || null,
    bollard_stern_launcher: docking.bollard_stern_launcher?.tag || null,
    bollard_stern_spring: docking.bollard_stern_spring?.tag || null,
    bollard_stern_across: docking.bollard_stern_across?.tag || null,
    first_bow_support_fender: docking.first_bow_support_fender?.tag || null,
    last_stern_support_fender: docking.last_stern_support_fender?.tag || null,
    docking_goal: docking.docking_goal?.id || null,
    position: docking.purpose === 'DOCK_IN_BERTH' ? docking.position : null,
    docking_in_line:
      docking.purpose === 'DOCK_IN_BERTH' ? docking.docking_in_line : null,
    docking_alongside:
      docking.purpose === 'DOCK_IN_BERTH' ? docking.docking_alongside : null,
    products: formatProductsToSave(docking.products || []),
    status: docking.status || 'WAITING',
    wait_at_anchoring: isNullOrUndefined(docking.wait_at_anchoring)
      ? null
      : docking.wait_at_anchoring === 'true',
    financial_tables: formatFinancialTablesToSave(
      docking.financial_tables || []
    ),
    operation_summary: {
      ...docking.operation_summary,
      nature_of_cargo: docking.operation_summary?.nature_of_cargo?.id || null,
    },
    operations: formatOperationsToSave(docking.operations || []),
    convoy_vessel: docking.convoy_vessel?.id,
  };
}

function formatFinancialTablesToSave(
  dockingFinancialTables: FinancialTableType[]
) {
  return dockingFinancialTables.map((financialTable) => {
    return {
      ...financialTable,
      financial_table_type: financialTable.financial_table_type.id,
      company: financialTable.company.id,
      // items: formatFinancialTableItemsToSave(financialTable.items),
    };
  });
}

// function formatFinancialTableItemsToSave(
//   financialTableItems: FinancialTableItemType[]
// ) {
//   const formatedFinancialTableItems: any = [];
//   financialTableItems.forEach((financialTableItem) => {
//     if (financialTableItem.company?.id) {
//       formatedFinancialTableItems.push({
//         ...financialTableItem,
//         company: financialTableItem.company?.id,
//         confirm_payment:
//           financialTableItem.confirm_payment === undefined
//             ? false
//             : financialTableItem.confirm_payment,
//       });
//     }
//   });
//   return formatedFinancialTableItems;
// }

function formatProductsToSave(values: any) {
  return values.map((product: any) => ({
    ...product,
    cargo_type: product.cargo_type?.name,
    operator: product.operator?.id,
    traffic_type: product.traffic_type?.id,
    storage:
      product.direct_discharge === 'false' && product.storage
        ? product.storage.id
        : null,
    direct_discharge: product.direct_discharge === 'true',
    tanking_confirmed:
      product.direct_discharge === 'false' &&
      product.tanking_confirmed === 'true',
    quantity: parseInt(product.quantity, 10),
  }));
}

export function handleDateAndTimeStopover(values: any) {
  return {
    expected_arrival: createDateString(values.expected_arrival),
    expected_departure: createDateString(values.expected_departure),
    expected_arrival_by_agent: createDateString(
      values.expected_arrival_by_agent
    ),
    actual_anchorage_arrival_time: createDateString(
      values.actual_anchorage_arrival_time
    ),
    expected_arrival_ais: createDateString(values.expected_arrival_ais),
  };
}

export function handleDateAndTimeStayment(values: any) {
  return {
    actual_time_of_arrival: createDateString(values.actual_time_of_arrival),
    port_stay_finished: createDateString(values.port_stay_finished),
  };
}

export function dockingDatesToString(dockings: any) {
  const dockingsDates: any = [];
  dockings.forEach((element: any) => {
    dockingsDates.push({
      ...element,
      expected_berthing: createDateString(element.expected_berthing),
      pilot_expected_on_board: createDateString(
        element.pilot_expected_on_board
      ),
      pilot_expected_leaving_on_board: createDateString(
        element.pilot_expected_leaving_on_board
      ),
      expected_unberthing: createDateString(element.expected_unberthing),
      status: element.status || 'WAITING',
      // berth: element.berth?.tag || null
    });
  });
  if (dockingsDates.length > 0) {
    return dockingsDates;
  }
  return null;
}

function formatProductsToForm(products: any) {
  const productsArray: any = [];
  products.forEach((element: any) => {
    productsArray.push({
      ...element,
      direct_discharge: element.direct_discharge?.toString(),
      tanking_confirmed: element.tanking_confirmed.toString(),
    });
  });
  return productsArray;
}

function formatDockingsDates(dockings: any) {
  const dockingsDates: any = [];
  if (dockings.length > 0) {
    dockings.forEach((element: any) => {
      dockingsDates.push({
        ...element,
        ...formatDockingToForm(element),
      });
    });
  }
  return dockingsDates;
}

function formatManoeuvreToSave(manoeuvre: ManoeuvreType) {
  if (isNullOrUndefined(manoeuvre) || isEmpty(manoeuvre)) {
    return;
  }
  return {
    first_pilot: manoeuvre.first_pilot?.id || null,
    second_pilot: manoeuvre.second_pilot?.id || null,
    practitioner: manoeuvre.practitioner?.id || null,
    on_duty_that_accompanied: manoeuvre.on_duty_that_accompanied?.id || null,
    bow_tugboat: manoeuvre.bow_tugboat?.id || null,
    stern_tugboat: manoeuvre.stern_tugboat?.id || null,
    amidship_tugboat: manoeuvre.amidship_tugboat?.id || null,
    standby_tugboat: manoeuvre.standby_tugboat?.id || null,
    towage_company: manoeuvre.towage_company?.id || null,
    mooring_company: manoeuvre.mooring_company?.id || null,
    pilotage_company: manoeuvre.pilotage_company?.id || null,
    pilot_on_board: createDateString(manoeuvre.pilot_on_board),
    pilot_leave_on_board: createDateString(manoeuvre.pilot_leave_on_board),
    stern_draught: manoeuvre.stern_draught,
    bow_draught: manoeuvre.bow_draught,
    supervised: manoeuvre.supervised?.toString() === 'true',
    confirmed: manoeuvre.confirmed?.toString() === 'true',
    did_internal_navigation:
      manoeuvre.did_internal_navigation?.toString() === 'true',
    did_departure_at_port_entrance:
      manoeuvre.did_departure_at_port_entrance?.toString() === 'true',
    manoeuvre_release: manoeuvre.manoeuvre_release,
  };
}

export function formatCompanyToForm(company: any): CompanyType {
  const companyData: CompanyType = {
    ...company,
    cnpj: maskString(company.cnpj, '##.###.###/####-##'),
    phone_number: maskPhoneNumber(company.phone_number),
    address: {
      ...company.address,
      postal_code: maskString(company.address?.postal_code, '#####-###'),
    },
    company_type:
      company.company_type.flatMap((company_type: CompanyTypeType) => [
        company_type.id,
      ]) || [],
  };

  return companyData;
}

export function formatCompanyTypeName(companyTypeId: number) {
  return companyTypes.find((companyType) => companyType.id === companyTypeId)
    ?.name;
}

export function formatVesselToSave(vessel: Record<string, any>) {
  const vesselToSave: Vessel = {
    ...vessel,
    id: vessel.id || null,
    shipowner: vessel.shipowner?.id || null,
    name: vessel.name,
    captaincy_registration: vessel.captaincy_registration
      ? vessel.captaincy_registration
      : null,
    forward_amidship: vessel.forward_amidship
      ? vessel.forward_amidship === 'true'
      : null,
  };
  return vesselToSave;
}
export function formatPilotToSave(pilot: Record<string, any>) {
  const pilotlToSave: PilotType = {
    id: pilot.id || null,
    name: pilot.name,
    function: pilot.function,
    company: pilot.company.id,
  };
  return pilotlToSave;
}

export function formatFinancialTablesToForm(dockings: DockingType[]) {
  return dockings?.map((docking) => {
    docking.financial_tables = docking.financial_tables.map(
      (financial_table) => {
        financial_table.key = financial_table.id;
        // financial_table.items =
        //   financial_table.items.map((item) => {
        //     item.key = item.id;
        //     return item;
        //   }) || [];
        return financial_table;
      }
    );
    return docking;
  });
}

export function formatOperationMerchantCEToSave(updates: any) {
  return {
    ...updates,
    origin_port: updates.origin_port?.id || updates.origin_port?.bi_trigram,
    destiny_port: updates.destiny_port?.id || updates.destiny_port?.bi_trigram,
    operator_company: updates.operator_company?.id,
    storages: updates.storages.map((storage: any) =>
      formatOperationStorageMerchantToSave(storage, updates.id)
    ),
  };
}

export function formatOperationStorageMerchantToSave(
  updates: any,
  merchantCeId: number
) {
  return {
    ...updates,
    storage_company: updates.storage_company?.id,
    merchant_ce_id: merchantCeId,
    cargos_storage: updates.cargos_storage.map((cargoStorage: any) => {
      return {
        ...cargoStorage,
        cargo: cargoStorage.cargo?.id,
        storage_merchant_id: updates.id,
      };
    }),
  };
}

export function formatCraneWorkPeriodToForm(workPeriod: WorkPeriodType) {
  return {
    ...workPeriod,
    start_of_period: createDate(workPeriod.start_of_period),
    end_of_period: createDate(workPeriod.end_of_period),
  };
}

export function convertProductsToString(
  products: ProductType[] | undefined,
  quantity: number | null = null
): string {
  let concatProducts = '';
  products?.slice(0, quantity || products.length).forEach((product, index) => {
    concatProducts = concatProducts.concat(
      `${product.cargo_type?.name || ''} (${product.quantity || ''} ${
        unitsList.find((item) => item.value === product.unit)?.abbreviation ||
        ''
      })${index === products.length - 1 ? '' : ', '}`
    );
  });

  if (quantity && products && products?.length > quantity) {
    concatProducts = concatProducts.slice(0, -2);
    concatProducts = concatProducts.concat('...');
  }
  return concatProducts;
}

export function formatOperationToForm(
  operation: OperationalOperationType
): OperationalOperationType {
  return {
    ...operation,
    real_operation_start: createDate(operation.real_operation_start),
    real_operation_finish: createDate(operation.real_operation_finish),
    expected_operation_start: createDate(operation.expected_operation_start),
    expected_operation_finish: createDate(operation.expected_operation_finish),
    inspection: operation.inspection?.toString(),
    is_cargo_transshipment: operation.is_cargo_transshipment?.toString(),
  };
}

function formatOperationsToSave(operations: OperationalOperationType[]) {
  return operations.map((operation) => formatOperationToSave(operation));
}

export function formatOperationToSave(
  operation: OperationalOperationType,
  filters?: NcmItemFilters[],
  ncmIds?: number[]
): OperationalOperationToSave {
  if (operation?.product_group && operation.product_group.id === 0) {
    operation.product_group = undefined;
  }
  let { product_group, inspection } = operation;
  if (operation.provisioned_cargos && operation.provisioned_cargos.length > 0) {
    product_group = undefined;
    inspection = 'false';
  }
  return {
    id: operation.id,
    docking_id: operation.docking_id,
    nature_of_cargo: operation.nature_of_cargo?.id,
    product_group: product_group?.id,
    inspection: inspection === 'true',
    inspection_type: operation?.inspection_type?.id || null,
    real_operation_start: createDateString(operation?.real_operation_start),
    real_operation_finish: createDateString(operation?.real_operation_finish),
    expected_operation_start: createDateString(
      operation?.expected_operation_start
    ),
    expected_operation_finish: createDateString(
      operation?.expected_operation_finish
    ),
    items: formatOperationProductsToSave(operation.items || []),
    provisioned_cargos: formatProvisionedCargosToSave(operation),
    is_cargo_transshipment:
      operation.nature_of_cargo?.id !== 2
        ? undefined
        : operation.is_cargo_transshipment === 'true',
    flow_rate: operation.flow_rate,
    manifolds: operation.manifolds,
    filters,
    ncm_item_ids: ncmIds,
  };
}

function formatOperationManifestsToSave(
  operationManifests: OperationManifestType[]
): OperationManifestToSave[] {
  return operationManifests.map((operationManifest) => ({
    ...operationManifest,
    merchants_ce: formatMerchantsCeToSave(operationManifest.merchants_ce || []),
    operation_type: operationManifest.operation_type?.id,
    loading_port: operationManifest.loading_port?.bi_trigram,
    unloading_port: operationManifest.unloading_port?.bi_trigram,
    cargo_navigation_type: operationManifest.cargo_navigation_type?.id,
    loading_terminal: operationManifest.loading_terminal?.id,
    unloading_terminal: operationManifest.unloading_terminal?.id,
  }));
}

export function formatOperationProductToSave(
  operationProduct: OperationProductType
): OperationalOperationProductToSave {
  return {
    ...operationProduct,
    operation_release: operationProduct.operation_release,
    cargo_type: operationProduct.cargo_type?.name,
    operator: operationProduct.operator?.id,
    user_release:
      operationProduct.user_release &&
      typeof operationProduct.user_release !== 'number'
        ? operationProduct.user_release?.id
        : null,
    operation_manifests: formatOperationManifestsToSave(
      operationProduct.operation_manifests || []
    ),
    compartments:
      operationProduct.compartments?.map((item: any) => {
        return {
          ...item,
          operation_product_id: operationProduct.id,
        };
      }) || [],
  };
}

function formatOperationProductsToSave(
  operationProducts: OperationProductType[]
): OperationalOperationProductToSave[] {
  return operationProducts.map((operationProduct) =>
    formatOperationProductToSave(operationProduct)
  );
}

function formatProvisionedCargoToSave(
  provisionedCargo: ProvisionedCargoType,
  operation: OperationalOperationType
): ProvisionedCargoToSave {
  return {
    id: provisionedCargo.id,
    supplier_company: provisionedCargo.supplier_company.id,
    supply_type: provisionedCargo.supply_type.name,
    total_weight: provisionedCargo.total_weight,
    operation_type: operation.operation_type?.id || null,
    operation_direction: operation.operation_direction || null,
    loading_port: operation.loading_port?.bi_trigram || null,
    unloading_port: operation.unloading_port?.bi_trigram || null,
    ncm_code: provisionedCargo.ncm_code,
    amount: provisionedCargo.amount,
    imdg_code: provisionedCargo.imdg_code?.id || null,
  };
}

function formatProvisionedCargosToSave(
  operation: OperationalOperationType
): ProvisionedCargoToSave[] {
  return (
    operation.provisioned_cargos?.map((provisionedCargo) =>
      formatProvisionedCargoToSave(provisionedCargo, operation)
    ) || []
  );
}

function formatMerchantsCeToSave(
  merchantsCe: OperationProductCeMercanteType[]
): OperationalMerchantCeToSave[] {
  return merchantsCe.map((merchantCe) => ({
    ...merchantCe,
    operator_company: merchantCe.operator_company?.id,
    origin_port: merchantCe.origin_port?.bi_trigram,
    destiny_port: merchantCe.destiny_port?.bi_trigram,
    storages: formatStoragesMerchantToSave(merchantCe.storages || []),
  }));
}

function formatStoragesMerchantToSave(
  storages: OperationProductStoragesMercanteType[]
): OperationalStorageMerchantToSave[] {
  return storages.map((storage) => ({
    ...storage,
    storage_company: storage.storage_company?.id,
    cargos_storage: formatCargoStorageMerchantToSave(
      storage.cargos_storage || []
    ),
  }));
}

function formatCargoStorageMerchantToSave(
  cargos: CargoStorageMerchantType[]
): OperationalCargoStorageMerchantToSave[] {
  return cargos.map((cargo) => ({
    ...cargo,
    cargo: cargo.cargo?.id,
    imdg_code:
      typeof cargo.imdg_code !== 'number'
        ? cargo.imdg_code?.id
        : cargo.imdg_code,
  }));
}

export function formatStoppageToSave(stoppage: any): StoppageToSave {
  return {
    id: stoppage.id || 0,
    start: createDateString(stoppage?.start),
    end: createDateString(stoppage?.end),
    motive: stoppage?.motive,
    is_total: stoppage?.is_total,
    observations: stoppage?.observations,
    operation_id: stoppage?.operation_id || 0,
    compartments: stoppage?.compartments,
    operation_cranes: stoppage?.operation_cranes,
  };
}

export function formatVesselToDescription(
  vessel: VesselType
): VesselTypeDescription {
  return {
    name: vessel.name,
    ship_type: shipTypesList.find((item) => item.id === vessel.ship_type?.name)
      ?.name,
    draught_max: addSuffix(formatNumberToLocale(vessel.draught_max), 'm'),
    forward_amidship:
      booleanList.find((item) => item.id === vessel.forward_amidship)?.name ||
      'Não',
    draught_min: addSuffix(formatNumberToLocale(vessel.draught_min), 'm'),
    mmsi: vessel.mmsi,
    loa: addSuffix(formatNumberToLocale(vessel.loa), 'm'),
    captaincy_registration: vessel.captaincy_registration,
    width: addSuffix(formatNumberToLocale(vessel.width), 'm'),
    dock_line_type: dockLineTypes.find(
      (item) => item.id === vessel.dock_line_type
    )?.name,
    dwt: addSuffix(formatNumberToLocale(vessel.dwt), 'ton'),
    flag: vessel.flag,
    shipowner: vessel.shipowner?.name,
  };
}

export function formatStopoverToDescription(
  stopover: StopoverType
): StopoverTypeDescription {
  return {
    shipping_agency: stopover.shipping_agency?.name,
    mercante_scale: stopover.mercante_scale
      ? String(stopover.mercante_scale)
      : null,
    charterer: stopover.charterer?.name,
    shipowner_trip: stopover.shipowner_trip,
    protective_agency: stopover.protective_agency?.name,
    arrival_draught: addSuffix(
      formatNumberToLocale(stopover.arrival_draught),
      'm'
    ),
    last_port_country: stopover.last_port?.country_name,
    last_port: stopover.last_port?.name,
    next_port_country: stopover.next_port?.country_name,
    next_port: stopover.next_port?.name,
    navigation_type: (stopover.navigation_type as SystemParamItemType)
      ?.description,
    expected_arrival: createDateStringPtBr(stopover.expected_arrival),
    duv: stopover.duv,
    vessel: stopover.vessel?.name || '',
    nature_of_stayment: stopover.nature_of_stayment,
    expected_departure: createDateStringPtBr(stopover.expected_departure),
    expected_arrival_ais: createDateStringPtBr(stopover.expected_arrival_ais),
  };
}

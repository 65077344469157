import { ExclamationCircleOutlined } from '@ant-design/icons';
import { FormInstance, message } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { debounce, isEmpty } from 'lodash';
import moment from 'moment';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Form, FormItem } from '../../components/antd/Form';
import { Select } from '../../components/antd/Select';
import { VesselInfos } from '../../components/common/vesselInfos/VesselInfos';
import { useGetCargoTypeQuery } from '../../services/cargoApi';
import {
  useCancelDockingMutation,
  useCreateDockingMutation,
  useUpdateDockingMutation,
} from '../../services/dockingApi';
import { useGetBerthsQuery } from '../../services/dockingPlacesApi';
import { usePatchNcmItemMutation } from '../../services/ncmItemApi';
import {
  useFilterStopoveresByNameOrCodeQuery,
  useGetOperatorsQuery,
} from '../../services/stopoverApi';
import {
  Docking as DockingType,
  ManoeuvreType,
  NcmItemFilters,
  Vessel as VesselType,
} from '../../types';
import {
  formatDockingToForm,
  formatDockingToSave,
} from '../../utils/formatters';
import { collapsePanelsListDocking } from '../../utils/lists';
import { hasUnallowedCargo, getAllowedBerth } from '../../utils/validators';
import { DockingFormHeader } from './dockingFormHeader/DockingFormHeader';
import { FormItemsFinancialOfficers } from './formItemsFinancialOfficers';
import { FormItemsOperationalOperation } from './formItemsOperationalOperation';
import { FormItemsPredictions } from './formItemsPredictions';
import { validateStopoverDockings } from './dockingStaymentDrawer/validators';

type DockingFormProps = {
  name: string;
  selectedDocking: DockingType | undefined;
  selectedVessel?: VesselType;
  setSelectedDocking?: (value: any) => void;
  selectedStopover: any;
  setSelectedStopover?: (value: any) => void;
  allowedBerth: any;
  currentDockingKey: number;
  setCurrentDockingKey: (value: any) => void;
  stopoverForm: FormInstance | undefined;
  isVisibleForm: boolean;
  setIsVisibleForm: (value: boolean) => void;
  addDocking?: (defaultValue?: any, insertIndex?: number | undefined) => void;
  removeDocking?: (index: number | number[]) => void;
  isDockingWindowContext?: boolean;
  onBack?: () => void;
  onSaveUpdate?: (dockingId: number, updateObj: any) => void;
  screen: 'STOPOVER' | 'AGENT_AREA';
  openCollapsePanels: string | string[];
  setOpenCollapsePanels: Dispatch<SetStateAction<string | string[]>>;
};

const collapsePanels = [
  'vessel',
  'stopover',
  'operation',
  'documentsConsents',
  'dockings',
  'financialOfficers',
  'operationsPanel',
  'generalData',
];

const FormWrapper = styled.div`
  padding: 32px;
`;

export function DockingForm(props: DockingFormProps) {
  const [form] = Form.useForm();
  const { screen, openCollapsePanels, setOpenCollapsePanels } = props;
  const stopoverCode = props.selectedStopover?.code;

  const [selectedDocking, setSelectedDocking] = useState({} as DockingType);
  const [hasEdited, setHasEdited] = useState(false);

  // berth allowed to this vessel
  const [allowedBerth, setAllowedBerth] = useState([]);
  // allowed cargo to selected berth
  const [allowedCargo, setAllowedCargo] = useState<any>();
  // allowed operators to selected berth
  const [allowedOperators, setAllowedOperators] = useState<any>([]);

  const [isCreatingNewDocking, setIsCreatingNewDocking] = useState(false);

  const [stopoverSearch, setStopoverSearch] = useState<string>('');

  const [selectedStopover, setSelectedStopover] = useState(Object);

  // ncms de cargas atribuidas quando screen = 'AGENT_AREA'
  const [ncmIds, setNcmIds] = useState<number[]>([]);
  const [ncmItemFilters, setNcmItemFilters] = useState<NcmItemFilters[]>([]);

  // berth data
  const { data: berthData, isLoading: isLoadingBerthData } = useGetBerthsQuery({
    shipType: '',
  });

  const { data: cargosData } = useGetCargoTypeQuery();
  const { data: operatorsData } = useGetOperatorsQuery();

  const [createDocking, { isLoading: isLoadingCreateDocking }] =
    useCreateDockingMutation();

  const [updateDocking, { isLoading: isLoadingUpdateDocking }] =
    useUpdateDockingMutation();

  const [patchNcmItem] = usePatchNcmItemMutation();

  useEffect(() => {
    if (props.isDockingWindowContext && isEmpty(props.selectedDocking)) {
      setIsCreatingNewDocking(true);
      form.setFieldsValue({});
    } else {
      setIsCreatingNewDocking(false);
    }
  }, [props.selectedDocking, props.isDockingWindowContext]);

  useEffect(() => {
    if (props.isDockingWindowContext) {
      if (isCreatingNewDocking) {
        setAllowedBerth(getAllowedBerth(selectedStopover?.vessel, berthData));
      } else {
        setAllowedBerth(
          getAllowedBerth(props.selectedStopover?.vessel, berthData)
        );
      }
    } else {
      setAllowedBerth(getAllowedBerth(props.selectedVessel, berthData));
    }
  }, [berthData, props.selectedStopover, selectedStopover]);

  useEffect(() => {
    // Seta os valores do stopover para o estado SelectedStopover
    if (props.isDockingWindowContext) {
      setSelectedStopover(props.selectedStopover);
    } else {
      setSelectedStopover(props.stopoverForm?.getFieldsValue());
    }
  }, [props.selectedStopover]);

  function validateDocking(docking: DockingType): boolean {
    if (
      screen === 'STOPOVER' &&
      docking.expected_berthing &&
      docking.expected_unberthing &&
      !docking.docking_place?.tag
    ) {
      // Só é permitido salvar previsão de estadia se informar o berço
      message.error(
        'Não é possível informar a previsão de estadia sem informar o berço.'
      );
      return false;
    }

    if (
      docking.expected_berthing &&
      docking.expected_unberthing &&
      moment(docking.expected_berthing) > moment(docking.expected_unberthing)
    ) {
      message.error(
        'A data de previsão da atracação não pode ser maior que a data de previsão de desatracação',
        15
      );
      return false;
    }

    if (
      docking.pilot_expected_on_board &&
      docking.pilot_expected_leaving_on_board &&
      moment(docking.pilot_expected_on_board) >
        moment(docking.pilot_expected_leaving_on_board)
    ) {
      message.error(
        'A data de previsão da prático a bordo não pode ser maior que a data de previsão de saída do prático a bordo',
        15
      );
      return false;
    }

    if (docking.purpose === 'DOCK_IN_BERTH') {
      const checkUnallowedCargo = hasUnallowedCargo(
        props.isDockingWindowContext ? allowedBerth : props.allowedBerth,
        docking
      );
      if (checkUnallowedCargo.length > 0) {
        message.error(
          `A mercadoria ${checkUnallowedCargo[0].name} não é aceita no novo berço. Por favor, revise as informações de mercadoria`
        );
        return false;
      }
    }

    return true;
  }

  async function handleFormSubmit(values: any) {
    // Sempre que for uma ação de salvar pela janela de atracação deve chamar os endpoints de update ou create,
    // São chamados na função saveDocking
    // Quando salvar pela programação de escalas deve chamar a callback de adicionar os dados da atracação ao formulário
    if (!validateDocking(values)) return; // Antes de prosseguir com o salvar valida se o docking pode ser salvo
    values.purpose = 'DOCK_IN_BERTH';
    let hasError = false;
    if (
      props.currentDockingKey !== null &&
      props.currentDockingKey !== undefined
    ) {
      // É uma edição de uma atracação
      if (props.isDockingWindowContext) {
        // Editando pela janela de atracações
        const valuesToUpdate = {
          ...props.selectedDocking,
          ...values,
          docking_manoeuvre: {
            ...props.selectedDocking?.docking_manoeuvre,
            ...values.docking_manoeuvre,
          } as ManoeuvreType,
          undocking_manoeuvre: {
            ...props.selectedDocking?.undocking_manoeuvre,
            ...values.undocking_manoeuvre,
          } as ManoeuvreType,
        };

        delete valuesToUpdate.berthing;
        delete valuesToUpdate.pilot;
        saveDocking(formatDockingToSave(valuesToUpdate));
      } else {
        // Editando pela programação da escala
        const docking = {
          ...props.selectedDocking,
          ...values,
          docking_manoeuvre: {
            ...props.selectedDocking?.docking_manoeuvre,
            ...values.docking_manoeuvre,
          } as ManoeuvreType,
          undocking_manoeuvre: {
            ...props.selectedDocking?.undocking_manoeuvre,
            ...values.undocking_manoeuvre,
          } as ManoeuvreType,
        };

        if (props.selectedStopover.id) {
          const update = formatDockingToSave(docking);
          const updatedDocking = await updateDocking(update);
          if ('data' in updatedDocking && props.setSelectedStopover) {
            const { data } = updatedDocking;
            props.setSelectedStopover({
              ...props.selectedStopover,
              dockings: props.selectedStopover.dockings.map(
                (docking: DockingType) => {
                  if (docking.id === update.id) {
                    return formatDockingToForm(data);
                  }
                  return docking;
                }
              ),
            });
            message.success('Alterações salvas com sucesso!', 5);
          } else {
            hasError = true;
          }
        } else {
          const dockings = props.stopoverForm?.getFieldsValue(['dockings']);
          dockings.dockings[props.currentDockingKey] = values;
          props.stopoverForm?.setFieldsValue({
            dockings: dockings.dockings,
          });
        }
      }
    } else if (props.isDockingWindowContext) {
      // É uma criação de uma nova atracação pela janela de atracações
      const valuesToUpdate = Object.assign(selectedDocking, { ...values });
      delete valuesToUpdate.berthing;
      delete valuesToUpdate.pilot;
      saveDocking(
        formatDockingToSave(valuesToUpdate, selectedStopover.dockings.length)
      );
    } else if (props.addDocking) {
      // É uma criação de atracação pela criação de escala na programação de escalas
      props.addDocking(values);
    } else {
      const create = formatDockingToSave(values);
      create.stopover_id = props.selectedStopover?.id;
      if (
        props.selectedStopover.arrival_draught >
        values.docking_place?.max_draught
      ) {
        return message.error(
          `O calado de chegada da escala (${props.selectedStopover.arrival_draught}m) é maior que o calado máximo do berço selecionado (${values.docking_place.max_draught}m)`,
          5
        );
      }
      const createdDocking = await createDocking(create);

      if ('data' in createdDocking && props.setSelectedStopover) {
        const { data } = createdDocking;
        const formattedData = formatDockingToForm(data);
        const oldDockings = props.selectedStopover?.dockings || [];
        props.setSelectedStopover({
          ...props.selectedStopover,
          dockings: [...oldDockings, formattedData],
        });

        message.success('Atracação criada com sucesso!', 5);
      } else {
        hasError = true;
      }
    }
    if (!hasError && !props.isDockingWindowContext) {
      if (props.setSelectedDocking) props.setSelectedDocking(undefined);
      props.setCurrentDockingKey(null);
      props.setIsVisibleForm(false);
      setHasEdited(false);
      form.resetFields();
    }
  }

  async function saveDocking(dockingToSave: any) {
    // Chama os endpoints para salvar a atracação
    let updatedDocking: any; // Recebe o valor da atracação atualizada depois de salva
    let createdDocking: any; // Recebe o valor da atracação criada depois de salva
    if (isCreatingNewDocking) {
      createdDocking = await createDocking({
        stopover_id: selectedDocking?.stopover_id,
        ...dockingToSave,
      });
      if ('data' in createdDocking) {
        if (props.onSaveUpdate) {
          props.onSaveUpdate(createdDocking.data.id, createdDocking.data);
          onBack();
        }
      }
    } else {
      updatedDocking = await updateDocking(dockingToSave);
      if ('data' in updatedDocking) {
        if (props.onSaveUpdate) {
          props.onSaveUpdate(updatedDocking.data.id, updatedDocking.data);
          onBack();
        }
      }
    }
  }

  useEffect(() => {
    // Formata os dados do docking para o formulário
    if (props.isDockingWindowContext) {
      const formatedDocking = formatDockingToForm({ ...props.selectedDocking });
      if (formatedDocking) {
        setSelectedDocking(formatedDocking);
        form.setFieldsValue(formatedDocking);
      } else {
        setSelectedDocking({} as DockingType);
        form.setFieldsValue({});
      }
    } else {
      setSelectedDocking(props.selectedDocking || ({} as DockingType));
      form.setFieldsValue(props.selectedDocking);
    }
    if (props.selectedDocking) {
      // Recupera os cargo e operators permitidos para a atracação
      setHasEdited(true);
      const berthCargo = berthData?.results?.find(
        (berth: any) => berth.tag === props.selectedDocking?.docking_place?.tag
      )?.cargo_type;
      setAllowedCargo(berthCargo);
      const berthOperators = berthData?.results?.find(
        (berth: any) => berth.tag === props.selectedDocking?.docking_place?.tag
      )?.operators;
      setAllowedOperators(berthOperators);
    }
  }, [props.selectedDocking]);

  useEffect(() => {
    // Reseta o docking selecionado quando o formulário não estiver mais na tela
    if (props.isVisibleForm === false) {
      if (props.setSelectedDocking) props.setSelectedDocking(undefined);
      if (!props.isDockingWindowContext) {
        props.setCurrentDockingKey(null);
      }
      form.resetFields();
    }
  }, [props.isVisibleForm]);

  function onBack() {
    if (props.isDockingWindowContext && props.onBack) {
      props.onBack();
    } else if (props.setSelectedDocking) props.setSelectedDocking(undefined);
    setOpenCollapsePanels(collapsePanelsListDocking);
    props.setIsVisibleForm(false);
  }

  function onRemove() {
    if (hasEdited) {
      confirm({
        title: 'Deseja realmente remover esta atracação?',
        icon: <ExclamationCircleOutlined />,
        content:
          'Depois que salvar a escala a atracação removida não poderá ser recuperada',
        onOk() {
          if (props.removeDocking) {
            props.removeDocking(props.currentDockingKey);
          }
          if (props.setSelectedDocking) props.setSelectedDocking(undefined);
          props.setCurrentDockingKey(null);
          props.setIsVisibleForm(false);
          setHasEdited(false);
        },
        okText: 'Sim',
        cancelText: 'Não',
      });
    } else {
      if (props.removeDocking) {
        props.removeDocking(props.currentDockingKey);
      }
      if (props.setSelectedDocking) props.setSelectedDocking(undefined);
      props.setCurrentDockingKey(null);
      props.setIsVisibleForm(false);
      setHasEdited(false);
    }
  }

  function onSelectBerth(berthTag: string) {
    const berthSelected = berthData?.results?.find(
      (berth: any) => berth.tag === berthTag
    );
    if (berthSelected && berthSelected.place_type === 'ANCHORING') {
      setAllowedCargo(cargosData?.results);
      setAllowedOperators(operatorsData?.results);
      return;
    }

    const berthCargo = berthData?.results?.find(
      (berth: any) => berth.tag === berthTag
    )?.cargo_type;
    setAllowedCargo(berthCargo);

    const berthOperators = berthData?.results?.find(
      (berth: any) => berth.tag === berthTag
    )?.operators;
    setAllowedOperators(berthOperators);
  }

  const { data: foundStopoveres } = useFilterStopoveresByNameOrCodeQuery(
    stopoverSearch,
    { skip: stopoverSearch === '' }
  );

  function onSeachStopover(val: string) {
    setStopoverSearch(val);
  }

  function onSelectStopover(_val: any, option: any) {
    const newSelectedStopover = foundStopoveres?.results?.find(
      (s: any) => s.id === option.value
    );
    setSelectedDocking(
      Object.assign(props.selectedDocking || ({} as DockingType), {
        stopover_id: option.value,
      })
    );
    setSelectedStopover(newSelectedStopover);
  }

  function expectedUnberthingLastDocking() {
    if (selectedStopover?.dockings?.length > 0) {
      if (props.currentDockingKey !== null && props.currentDockingKey >= 0) {
        if (
          selectedStopover?.dockings[props.currentDockingKey - 1]
            ?.expected_unberthing
        ) {
          return moment(
            selectedStopover?.dockings[props.currentDockingKey - 1]
              ?.expected_unberthing
          );
        }
        return null;
      }
      return moment(
        selectedStopover?.dockings[selectedStopover.dockings.length - 1]
          ?.expected_unberthing
      );
    }
    return moment(selectedStopover?.expected_arrival);
  }

  function pilotExpectedOnBoardNextDocking() {
    if (selectedStopover?.dockings?.length > 0) {
      if (props.currentDockingKey !== null && props.currentDockingKey >= 0) {
        if (
          selectedStopover?.dockings[props.currentDockingKey + 1]
            ?.pilot_expected_on_board
        ) {
          return moment(
            selectedStopover?.dockings[props.currentDockingKey + 1]
              ?.pilot_expected_on_board
          );
        }
        return null;
      }
      return null;
    }
    return null;
  }

  function expectedArrival() {
    if (props?.selectedStopover?.expected_arrival) {
      return moment(props?.selectedStopover.expected_arrival);
    }
    return null;
  }

  const [cancelDocking, { isLoading: isLoadingCancelDocking }] =
    useCancelDockingMutation();

  function onCancelDocking(evt: any) {
    evt.stopPropagation();
    confirm({
      title: 'Deseja realmente cancelar esta atracação?',
      icon: <ExclamationCircleOutlined />,
      content:
        'Depois que salvar a escala a atracação cancelada não poderá ser reativada',
      async onOk() {
        if (!isEmpty(props.selectedStopover) && !isEmpty(selectedDocking)) {
          const cancellation = await cancelDocking({
            id: selectedDocking?.id || 0,
          });

          if ('data' in cancellation && props.setSelectedStopover) {
            props.setSelectedStopover({
              ...props.selectedStopover,
              dockings: props.selectedStopover.dockings.map(
                (docking: DockingType) => {
                  if (docking.id === selectedDocking?.id) {
                    docking.status = cancellation.data.status;
                    return docking;
                  }
                  return docking;
                }
              ),
            });
          }
        }

        if (props.setSelectedDocking) props.setSelectedDocking(undefined);
        props.setCurrentDockingKey(null);
        props.setIsVisibleForm(false);
        setHasEdited(false);
      },
      okText: 'Sim',
      cancelText: 'Não',
    });
  }

  function onChangeSwitch(checked: boolean) {
    if (checked) {
      setOpenCollapsePanels(collapsePanels);
    } else {
      setOpenCollapsePanels([]);
    }
  }

  return (
    <>
      <DockingFormHeader
        code={stopoverCode}
        selectedVessel={props.selectedVessel}
        showExpandAll
        onChangeSwitch={onChangeSwitch}
        openCollapsePanels={openCollapsePanels}
        createFormTitle="Nova atracação"
        editFormTitle={`Atracação ${props.currentDockingKey + 1}`}
        onCancelDocking={(evt) => onCancelDocking(evt)}
        showCancelButton
        saveButtonTitle="Salvar atracação"
        cancelButtonTitle="Cancelar atracação"
        formName={props.name}
        onBack={() => onBack()}
        isCreatingNewDocking={isEmpty(selectedDocking)}
        dockingStatus={form.getFieldValue(['status'])}
        isLoading={
          isLoadingUpdateDocking ||
          isLoadingCreateDocking ||
          isLoadingCancelDocking
        }
      />
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        name={props.name}
        id={props.name}
        onFinish={handleFormSubmit}
        initialValues={selectedDocking}
        onValuesChange={() => {
          setHasEdited(true);
        }}
      >
        <FormItem noStyle name="index" />
        {isCreatingNewDocking && (
          <FormWrapper>
            <div style={{ marginBottom: '25px' }}>
              <FormItem label="Selecione uma escala já programada">
                <Select
                  placeholder="Preencha com o nome da embarcação ou número da escala"
                  showSearch
                  allowClear
                  notFoundContent={null}
                  filterOption={(input, option) =>
                    option!.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSearch={debounce(onSeachStopover, 400)}
                  onSelect={onSelectStopover}
                >
                  {foundStopoveres?.results
                    ?.filter(
                      (stopover: any) => stopover.current_status !== 'DONE'
                    )
                    .map((stopover: any, index: any) => {
                      return (
                        <Select.Option key={index} value={stopover.id}>
                          {`${stopover.vessel.name} - ${stopover.code}`}
                        </Select.Option>
                      );
                    })}
                </Select>
              </FormItem>
              <VesselInfos selectedVessel={selectedStopover?.vessel} />
            </div>
          </FormWrapper>
        )}
        <FormItemsPredictions
          form={form}
          readOnly={false}
          formDocking={form}
          manoeuvre="docking_manoeuvre"
          onSelectBerth={onSelectBerth}
          allowedBerth={allowedBerth}
          allowedCargo={allowedCargo}
          allowedOperators={allowedOperators}
          expectedArrival={expectedArrival()}
          expected_unberthing_last_docking={expectedUnberthingLastDocking()}
          pilot_expected_on_board_next_docking={pilotExpectedOnBoardNextDocking()}
          berthData={berthData}
          isLoadingBerthData={isLoadingBerthData}
          screen={screen}
          selectedDocking={selectedDocking}
          openCollapsePanels={openCollapsePanels}
          setOpenCollapsePanels={setOpenCollapsePanels}
        />
        {screen === 'AGENT_AREA' && (
          <FormItemsOperationalOperation
            updatedAt={form.getFieldValue('updated_at')}
            openCollapsePanels={openCollapsePanels}
            dockingId={selectedDocking.id}
            dockingForm={form}
            setOpenCollapsePanels={setOpenCollapsePanels}
            stopoverId={props.selectedStopover.id}
            ncmIds={ncmIds}
            setNcmIds={setNcmIds}
            ncmItemFilters={ncmItemFilters}
            setNcmItemFilters={setNcmItemFilters}
          />
        )}
        <FormItemsFinancialOfficers
          form={form}
          selectedStopover={props.selectedStopover}
          docking={selectedDocking}
          openCollapsePanels={openCollapsePanels}
          onOpenPanel={setOpenCollapsePanels}
        />
      </Form>
    </>
  );
}

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd';
import FormList from 'antd/lib/form/FormList';
import { useState } from 'react';

import {
  Button,
  Collapse,
  Form,
  InnerCollapse,
  Select,
} from '../../../components';
import { useGetPriceTablesQuery } from '../../../services/chargeApi';
import { ChargeType, FareType } from '../../../typesFinancial';
import { tableTypeList } from '../../../utils/lists';
import { TableTypeListType } from './chargeForm';
import { TableItemCollapse } from './tableItemCollapse';

const { Panel } = InnerCollapse;

export type TableTypesType = 'table_one' | 'table_two' | 'table_three';

type TableCollapseProps = {
  form: FormInstance;
  tableIndex: number;
  onSelectTableType: (value: TableTypesType) => void;
  availableTableTypes?: TableTypeListType[];
  selectedFare?: FareType;
  selectedCharge?: ChargeType;
  isAddCharge?: boolean;
};
export function TableCollapse(props: TableCollapseProps) {
  const {
    form,
    tableIndex,
    onSelectTableType,
    availableTableTypes,
    selectedFare,
    selectedCharge,
    isAddCharge,
  } = props;
  const [selectedTableType, setSelectedTableType] = useState<TableTypesType>();
  const [selectedTableTypeIndex, setSelectedTableTypeIndex] = useState<
    number | null
  >(null);
  const [selectedTableName, setSelectedTableName] = useState<string>(
    'Selecione uma tabela abaixo'
  );

  function onChangeTableType(value: TableTypesType) {
    const currentTablesArray = form.getFieldValue(['tables']);
    const newTablesArray = [...currentTablesArray];
    newTablesArray[tableIndex] = { table_type: value };
    form.setFieldsValue({
      tables: newTablesArray,
    });
    setSelectedTableType(value);
    onSelectTableType(value);
    setSelectedTableName(
      tableTypeList.find((tableType) => tableType.value === value)?.label || ''
    );
    setSelectedTableTypeIndex(
      tableTypeList.find((tableType) => tableType.value === value)?.index ||
        null
    );
  }

  const { data: priceTables, isLoading: isLoadingPriceTables } =
    useGetPriceTablesQuery();

  return (
    <Collapse
      style={{
        marginLeft: '-16px',
        marginRight: '-16px',
        marginTop: '0px',
      }}
      expandIconPosition="end"
      defaultActiveKey={[`table_${tableIndex}`]}
    >
      <Collapse.Panel key={`table_${tableIndex}`} header={selectedTableName}>
        <Form.Item label="Tabelas" required>
          <Select
            style={{ width: '100%' }}
            options={availableTableTypes}
            onSelect={(e: any) => onChangeTableType(e)}
          />
        </Form.Item>
        {selectedTableType && (
          <FormList name={[tableIndex, 'items']}>
            {(tableItems, { add: addItem, remove: removeItem }) => {
              return (
                <div>
                  <Button
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() => addItem({})}
                    disabled={selectedTableType === undefined}
                  >
                    Adicionar item
                  </Button>
                  {tableItems.length > 0 && (
                    <InnerCollapse
                      expandIconPosition="end"
                      style={{ marginTop: '16px' }}
                      activeKey={tableItems.map(
                        (item, index) => `table_item_${index}`
                      )}
                    >
                      {tableItems.map((tableItem, index) => {
                        return (
                          <Panel
                            key={`table_item_${index}`}
                            header=""
                            extra={
                              <Button
                                type="text"
                                icon={<DeleteOutlined />}
                                style={{ marginRight: '10px' }}
                                onClick={(evt) => {
                                  evt.stopPropagation();
                                  removeItem(index);
                                }}
                              >
                                Excluir
                              </Button>
                            }
                          >
                            <TableItemCollapse
                              form={form}
                              tableIndex={tableIndex}
                              itemIndex={index}
                              selectedTableType={selectedTableType}
                              selectedFare={selectedFare}
                              selectedCharge={selectedCharge}
                              isAddCharge={isAddCharge}
                              selectedTableItems={
                                priceTables?.find(
                                  (priceTable) =>
                                    priceTable.number === selectedTableTypeIndex
                                )?.items
                              }
                            />
                          </Panel>
                        );
                      })}
                    </InnerCollapse>
                  )}
                </div>
              );
            }}
          </FormList>
        )}
      </Collapse.Panel>
    </Collapse>
  );
}

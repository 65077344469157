import { SyncOutlined } from '@ant-design/icons';
import { Col, message, Row, Skeleton, Space } from 'antd';
import { Rule } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

import {
  Alert,
  Button,
  Form,
  FormItem,
  FormItemInput,
  FormItemSelect,
  Modal,
  Select,
} from '../../../components';
import { FormItemInputNumber } from '../../../components/antd/formItems/FormItemInputNumber';
import {
  useGetCompaniesQuery,
  useLazyGetCompaniesQuery,
} from '../../../services/companyApi';
import { useLazyGetVesselMtDataQuery } from '../../../services/marineTrafficApi';
import {
  useCreateVesselMutation,
  useDeleteVesselMutation,
  useGetPortsCountriesQuery,
  useUpdateVesselMutation,
} from '../../../services/vesselApi';
import { usePatchVesselRequestUpdateMutation } from '../../../services/vesselRequestUpdateApi';
import {
  CompanyType,
  VesselRequestUpdateType,
  Vessel as VesselType,
} from '../../../types';
import { formatVesselToSave } from '../../../utils/formatters';
import {
  booleanList,
  dockLineTypes,
  shipTypesList,
} from '../../../utils/lists';
import { createDateStringPtBr } from '../../../utils/utils';
import {
  captaincyRegistrationRules,
  mmsiRules,
  validateIMO,
} from '../../../utils/validators';
import { FormItemsWrapper } from '../company/companyForm';
import { RegisterHeaderForm } from '../registerHeaderForm';
import { VesselToForm } from './Vessel';

type VesselFormProps = {
  name: string;
  onClose: () => void;
  selectedVessel: VesselToForm | null;
  vesselRequestsUpdateData: VesselRequestUpdateType[];
  isLoadingVesselRequestsUpdateData?: boolean;
};

export const imoRules: Rule[] = [
  {
    validator: (_, value) => validateIMO(value),
    message: 'IMO inválido',
  },
];

export function VesselForm(props: VesselFormProps) {
  const {
    name,
    selectedVessel,
    onClose,
    vesselRequestsUpdateData,
    isLoadingVesselRequestsUpdateData,
  } = props;
  const [searchShipownerName, setShipownerName] = useState('');

  const { data: foundShipowners } = useGetCompaniesQuery({
    name_or_cnpj: searchShipownerName,
    // company_type: 'SHIPOWNER',
  });

  const [getCompaniesData, { isLoading: isLoadingGetCompaniesQuery }] =
    useLazyGetCompaniesQuery();

  const [searchCountryName, setCountryName] = useState('');
  const [searchCountryCode, setCountryCode] = useState('');

  const [showSynchronizeMTModal, setShowSynchronizeMTModal] = useState(false);

  const [requestUpdateId, setRequestUpdateId] = useState(0);
  const [showRejectSyncModal, setShowRejectSyncModal] = useState(false);
  const [rejectMotive, setRejectMotive] = useState('');

  const { data: countriesData } = useGetPortsCountriesQuery({
    countryName: searchCountryName,
    countryCode: searchCountryCode,
  });

  const [createVessel, { isSuccess: isSuccessSaveVessel }] =
    useCreateVesselMutation();
  const [
    updateVessel,
    { isSuccess: isSuccessUpdateVessel, isLoading: isLoadingUpdateVessel },
  ] = useUpdateVesselMutation();
  const [deleteVessel, { isSuccess: isSuccessDeleteVessel }] =
    useDeleteVesselMutation();

  const [
    patchVesselRequestUpdate,
    { isLoading: isLoadingPatchVesselRequestUpdate },
  ] = usePatchVesselRequestUpdateMutation();

  const [getVesselMtData, { isLoading: isLoadingGetVesselMt }] =
    useLazyGetVesselMtDataQuery();

  function validateVessel(vessel: VesselType) {
    if (
      vessel.draught_min &&
      vessel.draught_max &&
      vessel.draught_min > vessel.draught_max
    ) {
      message.error('Calado máximo não pode ser menor que o calado mínimo');
      return false;
    }
    return true;
  }

  async function handleForm(values: any) {
    if (!validateVessel(values)) return;

    if (isEmpty(selectedVessel)) {
      await createVessel(formatVesselToSave(values));
    } else {
      await updateVessel(formatVesselToSave(values));
    }
  }

  async function onDeleteVessel() {
    if (selectedVessel && selectedVessel.id) {
      await deleteVessel(selectedVessel.id);
    }
  }

  function handleSearchCountryName(value: string) {
    if (!isEmpty(searchCountryCode)) {
      setCountryCode('');
    }
    setCountryName(value);
  }

  useEffect(() => {
    if (selectedVessel?.flag) {
      setCountryCode(selectedVessel.flag);
    }
    if (selectedVessel?.shipowner?.name) {
      setShipownerName(selectedVessel?.shipowner?.name);
    }
  }, [selectedVessel]);

  useEffect(() => {
    if (isSuccessSaveVessel || isSuccessUpdateVessel) {
      message.success('Embarcação salva');
      onClose();
    }
  }, [isSuccessSaveVessel, isSuccessUpdateVessel]);

  useEffect(() => {
    if (isSuccessDeleteVessel) {
      message.success('Embarcação excluída');
      onClose();
    }
  }, [isSuccessDeleteVessel]);
  function getCompanyName(cnpj: string, name: string) {
    if (cnpj) {
      return `${cnpj} - ${name}`;
    }
    return name;
  }
  function shipownerOptionRenderer(option: Record<string, any>) {
    return (
      <Select.Option key={option.key} value={option.id}>
        {getCompanyName(option.cnpj, option.name)}
      </Select.Option>
    );
  }

  function countryOptionRenderer(option: Record<string, any>) {
    return (
      <Select.Option key={option.key} value={option.country_code}>
        {option.country_name}
      </Select.Option>
    );
  }

  function getMessage(item: VesselRequestUpdateType) {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span style={{ fontWeight: '700', fontSize: '14px' }}>
          Alteração de dados de embarcação
        </span>
        <div style={{ display: 'flex', gap: '8px' }}>
          <Button type="default" danger>
            Rejeitar
          </Button>
          <Button
            type="primary"
            icon={<SyncOutlined />}
            onClick={() => {
              setRequestUpdateId(item.id || 0);
              setShowSynchronizeMTModal(true);
            }}
          >
            Sincronizar
          </Button>
        </div>
      </div>
    );
  }

  function getDescriptionRequest(item: VesselRequestUpdateType) {
    const company = item.company_agency as CompanyType;
    const vessel = item.vessel as VesselType;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
        <span>
          [{company.name}] solicitou alterações de dados da embarcação [
          {vessel.name}].{' '}
        </span>
        <span>
          Motivo: <span style={{ fontStyle: 'italic' }}>“{item.motive}“</span>
        </span>
        <strong>
          Clique em “Sincronizar” para atualizar os dados desta embarcação com o
          Marine Traffic.
        </strong>
        <span style={{ textAlign: 'right', fontSize: '12px' }}>
          Solicitado em {createDateStringPtBr(item.created_at)}
        </span>
      </div>
    );
  }

  function handleConfirmMtSync() {
    getVesselMtData(`imo:${selectedVessel?.imo}`).then(async (response) => {
      if ('data' in response) {
        let shipowner = null;
        if (response.data?.owner) {
          await getCompaniesData({ name: response.data?.owner }).then(
            (response) => {
              if ('data' in response) {
                if (
                  response?.data?.results &&
                  response?.data?.results.length > 0
                ) {
                  shipowner = response.data?.results[0];
                }
              }
            }
          );
        }
        const vesselMt = response.data;
        const dataVessel = {
          ...selectedVessel,
          mmsi: vesselMt?.mmsi || selectedVessel?.mmsi,
          name: vesselMt?.name || selectedVessel?.name,
          loa: vesselMt?.length_overall || selectedVessel?.loa,
          gross_tonage:
            vesselMt?.gross_tonnage || selectedVessel?.gross_tonnage,
          dwt: vesselMt?.summer_dwt || selectedVessel?.dwt,
          shipowner: shipowner || selectedVessel?.shipowner,
          draught_min: vesselMt?.draught || selectedVessel?.draught_min,
          width: vesselMt?.breadth_extreme || selectedVessel?.width,
        };
        await updateVessel(formatVesselToSave(dataVessel));
        await patchVesselRequestUpdate({
          id: requestUpdateId,
          is_confirmed: true,
        });
      }
    });
  }

  function confirmSyncReject() {}

  return (
    <>
      <Modal
        visible={showRejectSyncModal}
        title="Tem certeza que deseja rejeitar essa solicitação?"
        className="TOSWarningModal"
        onCancel={() => {
          setRejectMotive('');
          setShowRejectSyncModal(false);
        }}
        width={600}
        footer={null}
      >
        <Row gutter={24}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0 15px',
            }}
          >
            <span>Para rejeitar a solicitação, preencha o motivo abaixo:</span>
          </div>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <TextArea
              maxLength={500}
              rows={2}
              showCount
              required
              value={rejectMotive}
              onChange={(e) => setRejectMotive(e.target.value)}
            />
          </Col>
        </Row>

        <Row justify="end">
          <Space size={12}>
            <Button
              type="text"
              onClick={() => {
                setRejectMotive('');
                setShowRejectSyncModal(false);
              }}
            >
              Voltar
            </Button>
            <Button
              type="primary"
              danger
              disabled={!rejectMotive}
              onClick={confirmSyncReject}
            >
              Rejeitar
            </Button>
          </Space>
        </Row>
      </Modal>
      <Modal
        key="sync_marine_traffic"
        visible={showSynchronizeMTModal}
        className="TOSWarningModal"
        closable
        onCancel={() => setShowSynchronizeMTModal(false)}
        destroyOnClose
        title="Atenção"
        footer={
          <>
            <Button
              type="default"
              danger
              onClick={() => setShowSynchronizeMTModal(false)}
              disabled={
                isLoadingGetVesselMt ||
                isLoadingGetCompaniesQuery ||
                isLoadingUpdateVessel ||
                isLoadingPatchVesselRequestUpdate
              }
            >
              Cancelar
            </Button>
            <Button
              type="primary"
              onClick={handleConfirmMtSync}
              icon={<SyncOutlined />}
              loading={
                isLoadingGetVesselMt ||
                isLoadingGetCompaniesQuery ||
                isLoadingUpdateVessel ||
                isLoadingPatchVesselRequestUpdate
              }
            >
              Sincronizar
            </Button>
          </>
        }
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0 15px',
          }}
        >
          <p>
            Para realizar a alteração de dados da embarcação, será realizada uma
            sincronização com o Marine Traffic, que pode acarretar em cobrança
            adicional.
          </p>
          <p>
            Certifique-se de que os dados estão atualizados no Marine Traffic
            antes de fazer a solicitação.
          </p>
        </div>
      </Modal>
      <Form
        name={name}
        layout="vertical"
        onFinish={handleForm}
        initialValues={selectedVessel || {}}
      >
        <RegisterHeaderForm
          onClose={onClose}
          formName={name}
          allowDelete={!isEmpty(selectedVessel)}
          onDelete={onDeleteVessel}
        >
          {isEmpty(selectedVessel) ? (
            <div className="title">NOVA EMBARCAÇÃO</div>
          ) : (
            <div className="title">{selectedVessel?.name}</div>
          )}
        </RegisterHeaderForm>
        {isLoadingVesselRequestsUpdateData ? (
          <Skeleton active />
        ) : (
          vesselRequestsUpdateData.map((item) => (
            <Alert
              type="warning"
              message={getMessage(item)}
              description={getDescriptionRequest(item)}
              style={{ margin: '24px 16px' }}
            />
          ))
        )}
        <FormItemsWrapper>
          <Row gutter={16}>
            <FormItem noStyle name="id" />
            <FormItemInput colSpan={12} label="Nome" name="name" required />
            <FormItemInput
              colSpan={12}
              label="IMO"
              name="imo"
              rules={imoRules}
            />
          </Row>
          <Row>
            <FormItemSelect
              placeholder="Selecionar"
              label="Armador"
              name={['shipowner', 'id']}
              showSearch
              onSearch={setShipownerName}
              debounceDelay={400}
              optionRenderer={shipownerOptionRenderer}
              dataList={foundShipowners?.results
                .slice()
                .sort((a, b) => a.name.localeCompare(b.name))}
              allowClear
            />
          </Row>
          <Row gutter={16}>
            <FormItemSelect
              colSpan={12}
              placeholder="Selecionar"
              label="Tipo de embarcação"
              name="ship_type"
              showSearch
              dataList={shipTypesList
                .slice()
                .sort((a, b) => a.name.localeCompare(b.name))}
            />
            <FormItemSelect
              colSpan={12}
              placeholder="Selecionar"
              label="Avante à meia-nau"
              name="forward_amidship"
              dataList={booleanList}
            />
          </Row>
          <Row gutter={16}>
            <FormItemInput
              colSpan={12}
              label="MMSI"
              name="mmsi"
              rules={mmsiRules}
              maxLength={9}
            />
            <FormItemInput
              colSpan={12}
              label="Inscrição da capitania"
              name="captaincy_registration"
              rules={captaincyRegistrationRules}
              maxLength={10}
            />
          </Row>
          <Row gutter={16}>
            <FormItemSelect
              colSpan={12}
              label="Tipo de cabo amarração"
              name="dock_line_type"
              placeholder="Selecionar"
              dataList={dockLineTypes}
            />
            <FormItemSelect
              colSpan={12}
              label="Bandeira"
              placeholder="Selecionar"
              name="flag"
              showSearch
              onSearch={handleSearchCountryName}
              debounceDelay={400}
              dataList={countriesData?.results}
              optionRenderer={countryOptionRenderer}
              allowClear
            />
          </Row>
          <Row gutter={16}>
            <FormItemInputNumber
              colSpan={12}
              label="Calado máximo"
              name="draught_max"
              addonAfter="m"
            />
            <FormItemInputNumber
              colSpan={12}
              label="Calado mínimo"
              name="draught_min"
              addonAfter="m"
            />
          </Row>
          <Row gutter={16}>
            <FormItemInputNumber
              colSpan={12}
              label="LOA - Comprimento"
              name="loa"
              addonAfter="m"
            />
            <FormItemInputNumber
              colSpan={12}
              label="Boca - Largura"
              name="width"
              addonAfter="m"
            />
          </Row>
          <Row gutter={16}>
            <FormItemInputNumber
              colSpan={12}
              label="DWT - Porte"
              name="dwt"
              addonAfter="ton"
            />
            <FormItemInputNumber
              colSpan={12}
              label="Arqueação bruta"
              name="gross_tonnage"
              addonAfter="ton"
            />
          </Row>
        </FormItemsWrapper>
      </Form>
    </>
  );
}
